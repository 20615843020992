
    .manage-content {
        padding: 30px;
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;

        .manage-head {
            margin-bottom: 20px;
            text-align: right;

            .creationBtn {
                /*width: 80px;*/
                height: 36px;
                color: #ffffff;
                background: #1222D8;
                border-color: #1222D8;
                line-height: 0;
                padding: 0 12px;
                border-radius: 10px;
            }
        }

        .table-content {
            flex: 1;
            height: 1%;

            .train-name {
                /*text-overflow: ellipsis;*/
                /*overflow: hidden;*/
                /*white-space: nowrap;*/
            }

            .unable-btn {
                color: #999999 !important;
            }
        }

        .pages-center {
            text-align: center;
            margin-top: 20px;
        }
    }
